import React from 'react';

export const CloneIcon = () => {
    return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path
            d="M808.768 197.312c10.432 0 17.408 6.912 17.408 17.344l0 485.568c0 10.368-6.976 17.344-17.408 17.344l-87.296 0c-19.136 0-34.944 15.552-34.944 34.624 0 19.136 15.808 34.688 34.944 34.688l104.768 0c38.464 0 69.824-31.168 69.824-69.312l0-520.32C896 159.168 864.64 128 826.176 128l-384 0c-38.4 0-69.824 31.232-69.824 69.312l0 34.688c0 19.072 15.68 34.688 34.88 34.688 19.2 0 34.88-15.616 34.88-34.688L442.112 214.656c0-10.432 6.976-17.344 17.408-17.344L808.768 197.312z"
            p-id="17237" fill="#ffffff"></path>
        <path
            d="M128 363.968l0 469.376C128 867.84 160.32 896 199.808 896l394.944 0c39.488 0 71.872-28.16 71.872-62.656L666.624 363.968c0-34.432-32.384-62.592-71.872-62.592L199.808 301.376C160.32 301.376 128 329.536 128 363.968z"
            p-id="17238" fill="#ffffff"></path>
    </svg>;
};

export const ArrowIcon = () => {
    return <svg className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
        <path
            d="M723.882667 895.701333c-9.6 0-19.285333-3.2-27.39199999-9.6l-423.808-340.906666a43.733333 43.733333 0 0 1-1e-8-68.096L694.698667 137.984A43.690667 43.690667 0 0 1 749.397333 206.08l-379.605333 305.109333 381.525333 306.77333401a43.690667 43.690667 0 0 1-27.434666 77.73866599z"
            fill="#ffffff" p-id="21720"></path>
    </svg>;
};

